import PickupTimeSelectV1 from "./PickupTimeSelectV1.vue";
import PickupTimeSelect from "./PickupTimeSelect.vue";
import { defineFeatureComponent } from "@/core-ui";

export default defineFeatureComponent({
  name: "PickupTimeSelect",
  features: [
    {
      feature: "checkinFlowVersion",
      variant: "V1",
      component: PickupTimeSelectV1,
    },
    {
      feature: "checkinFlowVersion",
      variant: "V2",
      component: PickupTimeSelect,
    },
  ],
});
