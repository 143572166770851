<script>
import { nextInterval } from "@/utils/datetime";

import {
  parseISO,
  format,
  isBefore,
  isDate,
  setHours,
  startOfHour,
  addMinutes,
  addDays,
  differenceInMinutes,
  isTomorrow,
} from "date-fns";
import gql from "graphql-tag";
import { chain, head } from "lodash";

const QUOTE_TIME_BUY_COUNTS = gql`
  query QUOTE_TIME_BUY_COUNTS($storeId: ID!) {
    buys: buyView(storeId: $storeId) {
      id
      estimatedPickupAt
      status
    }
  }
`;

export default {
  name: "PickupTimeSelectV1",
  props: {
    // v-model
    value: {
      type: [Date, String],
      default: undefined,
    },
    // interval in minutes between each selectable time
    interval: {
      type: Number,
      default: 15,
    },
    // if required is true and value is ever null,
    // the first available time will automatically be selected
    // otherwise, the selector will just use the null value
    required: {
      type: Boolean,
      default: false,
    },
    // really only used with the prediction service for now,
    // but who knows?
    loading: {
      type: Boolean,
      default: false,
    },
    // control the error state from the parent via validation
    // or some other mechanism
    error: {
      type: Boolean,
      default: false,
    },
    storeId: { type: [String, Number], default: undefined },
  },
  apollo: {
    // Query the cache for the current buys and their pickup times
    buys: {
      query: QUOTE_TIME_BUY_COUNTS,
      variables() {
        return { storeId: this.$route.params.storeId };
      },
      // We only care about open buys
      update({ buys }) {
        return (buys || []).filter(({ status }) => status === "open");
      },
      fetchPolicy: "cache-and-network",
    },
  },
  data: (vm) => ({
    // increment button disabled prop
    incrementDisabled: false,
    // decrement button disabled prop
    decrementDisabled: true,
    // trying to control re-evaluating times on every change
    initialValue: parseISO(vm.value),
    // init buys for apollo query
    buys: [],
  }),
  computed: {
    internalValue: {
      // Return the value prop
      get() {
        if (typeof this.value === "string") {
          // If it's an empty string, return null
          if (this.value.length < 1) return null;

          // If the date parses successfully, return it
          const parsedDate = new Date(this.value);
          if (isDate(parsedDate)) return parsedDate;

          // Otherwise, return null
          return null;
        }
        return this.value;
      },
      // Parse the time string and emit the value
      set(v) {
        this.toggleDisabled();
        this.$emit("input", v);
      },
    },
    // Returns a list of times starting with `this.interval` minutes from now
    // and ending at 10p.
    times() {
      const now = new Date();
      const todayTimes = this.buildListOfTimes(
        now,
        addMinutes(this.startOfGivenHour(now, 22), 15)
      );
      const tomorrowTimes = this.buildListOfTimes(
        this.startOfGivenHour(addDays(now, 1), 9),
        this.startOfGivenHour(addDays(now, 1), 22)
      );
      const list = todayTimes.concat(["TOMORROW"]).concat(tomorrowTimes);
      // If initialValue is set and it's not within today's or tomorrow's
      // range, add it to the list.
      //
      // Otherwise, return today's list concatenated
      // with tomorrow's list.
      if (this.initialValue && isBefore(this.initialValue, head(list))) {
        return [this.initialValue].concat(list);
      }
      return list;
    },
    // public
    hasError() {
      return this.error || (this.required && !!this.internalValue) || false;
    },
    // Returns an object where the keys are time slots and the values are integers
    // Ex:
    // {
    //    "2019-08-20T22:00:00.000Z": 2,
    //    "2019-08-02T23:00:00.000z": 1,
    //    ...
    // }
    buyCounts() {
      if (!this.buys || !this.buys.length) return {};

      return chain(this.buys)
        .groupBy("estimatedPickupAt")
        .mapKeys((_v, k) => parseISO(k).toJSON())
        .mapValues((v) => v.length)
        .value();
    },
    hint() {
      return (
        (this.internalValue && isTomorrow(this.internalValue) && "Tomorrow") ||
        null
      );
    },
  },
  watch: {
    // If required === true, make sure a value is always selected
    value() {
      if (!this.required) return;

      this.selectInternalValue();
    },
  },
  // Make sure a pickup time is selected by default if a value is provided
  mounted() {
    this.selectInternalValue();
  },
  methods: {
    // Increases the selected index of the select field by 1 unless we're
    // at max.
    increment() {
      // Stop if we're at max. This _should_ never happen
      // because the button should already be disabled.
      if (this.indexIsMax()) return;

      let interval = 1;
      // Skip over `TOMORROW`
      if (this.times[this.currentIndex() + interval] === "TOMORROW") {
        interval = 2;
      }
      if (this.currentIndex() - interval > this.times.length - 1) return;

      // Select the next value
      this.internalValue = this.times[this.currentIndex() + interval];

      // Increment the selected index
      //this.selectItem(this.currentIndex() + interval);
    },
    // Decreases the selected index of the select field by 1 unless we're
    // at min
    decrement() {
      // Stop if we're at min. This _should_ never happen
      // because the button should already be disabled.
      if (this.indexIsMin()) return;

      let interval = 1;
      // Skip over `TOMORROW`
      if (this.times[this.currentIndex() - interval] === "TOMORROW") {
        interval = 2;
      }
      if (this.currentIndex() - interval < 0) return;

      // Select the previous value
      this.internalValue = this.times[this.currentIndex() - interval];
      // Decrement the selected index
      //this.selectItem(this.currentIndex() - interval);
    },
    // Shorthand for selecting an item
    selectItem(index) {
      this.$refs.select.setValue(this.$refs.select.items[index]);
    },
    // The $select selectedIndex
    currentIndex() {
      return this.times.indexOf(this.internalValue);
    },
    // Is the current index the last item in the items list?
    indexIsMax() {
      return this.currentIndex() === this.times.length - 1;
    },
    // Is the current index the first item in the items list?
    indexIsMin() {
      return this.currentIndex() === 0;
    },
    toggleDisabled() {
      // Toggle disabled status for increment and decrement buttons
      this.$nextTick(() => {
        if (this.currentIndex() === -1) {
          this.incrementDisabled = false;
          this.decrementDisabled = true;
          return;
        }
        if (this.indexIsMax()) {
          this.incrementDisabled = true;
        } else {
          this.incrementDisabled === true && (this.incrementDisabled = false);
        }
        if (this.indexIsMin()) {
          this.decrementDisabled = true;
        } else {
          this.decrementDisabled === true && (this.decrementDisabled = false);
        }
      });
    },
    // If there is no value, select the first item if required
    // Try to select the value's index in items
    // Otherwise, select the first item
    selectInternalValue() {
      if (!this.internalValue) return this.toggleDisabled();

      const val =
        (this.internalValue.toISOString && this.internalValue.toISOString()) ||
        this.internalValue;
      let index = this.times
        .map((t) => (t.toISOString && t.toISOString()) || t)
        .indexOf(val);
      // avoid selecting "tomorrow"
      index = index > -1 ? index : (this.times[0] === "TOMORROW" && 1) || 0;
      return this.selectItem(index);
    },
    // String -> String
    //  Checks `item` (time string) against buyCounts. If there
    //  are buys during that slot, the number will be appended
    //  to the end of the time string, formatted as:
    //    30 minutes (integer)  <- within an hour and with buy count
    //    30 minutes            <- within an hour and with no buy count
    //    h:mma (Integer)       <- with buy count
    //    h:mma                 <- without buy count
    listDisplayTime(item) {
      if (!item) {
        return "";
      }

      const count = this.buyCounts[item.toJSON()];

      const fmtString = (count && `h:mm a (${count})`) || `h:mm a`;

      return format(item, fmtString);
    },
    displayTime(item) {
      if (!item) {
        return "";
      }

      const now = new Date();

      // If the time is less than an hour away and `useWordDistance` is true, show:
      //  - 15: A few minutes [(buyCount)]
      //  - 15-59: n minutes [(buyCount)]
      //  - 1h+: m hour/s, n minutes [(buyCount)]
      if (isBefore(item, addMinutes(now, 16))) {
        return "A few minutes";
      } else if (isTomorrow(item)) {
        const fmtString = `h:mm a`;
        return `Tomorrow at ${format(item, fmtString)}`;
      } else {
        const totalMinutes = this.slotFromTime(item);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        if (hours > 0) {
          let hoursText = `${hours} hours`;
          if (hours === 1) {
            hoursText = `${hours} hour`;
          }

          if (minutes > 0) {
            return `${hoursText}, ${minutes} minutes`;
          }

          return `${hoursText}`;
        } else {
          return `${minutes} minutes`;
        }
      }
    },
    // String -> Integer
    // Turns a duration of minutes into 15, 30, 45, 60, etc.
    slotFromTime(time) {
      return (
        Math.floor(Math.abs(differenceInMinutes(time, new Date())) / 15) * 15
      );
    },
    // Date -> Date -> [Date]
    // Returns a list of time slots between `start` and `end`, exclusive
    buildListOfTimes(start, end) {
      const list = [];

      for (
        let d = nextInterval(start, this.interval);
        isBefore(d, end);
        d = nextInterval(d, this.interval)
      ) {
        list.push(d);
      }
      return list;
    },
    // Returns a native date which is at the start of the given `hour`.
    // `date` is used as a reference point.
    startOfGivenHour(date, hour) {
      return startOfHour(setHours(date, hour));
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-space-between align-center pickup-time-select">
      <div>
        <v-btn text icon :disabled="decrementDisabled" @click="decrement">
          <v-icon v-text="`$vuetify.icons.minus`" />
        </v-btn>
      </div>
      <div class="quoted-time-container">
        <v-select
          ref="select"
          v-model="internalValue"
          hide-details
          label="Quoted time"
          :items="times"
          dense
          flat
          solo
          :append-icon="null"
          :loading="loading"
          :hint="hint"
          :persistent-hint="!!hint"
        >
          <template #item="{ item }">
            <slot name="item" :item="item">
              <template v-if="item === 'TOMORROW'">
                <v-list-item class="ui lighten-2" disabled>
                  <v-list-item-content v-text="item" />
                </v-list-item>
              </template>
              <template v-else> {{ listDisplayTime(item) }} </template>
            </slot>
          </template>
          <template #selection="{ item }">
            <slot name="selection" :item="item">
              {{ listDisplayTime(item) }}
            </slot>
          </template>
        </v-select>
      </div>
      <v-btn text icon :disabled="incrementDisabled" @click="increment">
        <v-icon v-text="`$vuetify.icons.plus`" />
      </v-btn>
    </div>
    <div class="align-self-center display-time-period">
      {{ displayTime(internalValue) }}
    </div>
  </div>
</template>

<style scoped>
.display-time-period {
  z-index: 1000;
}

.quoted-time-container {
  min-width: 120px;
}
</style>
<style>
/*
    There is a hidden input in the selection slot.
  I _think_ it's used as way to fill remaining width in its
  container. For this component, we don't care about that.
  We always want the select field to take up the least
  amount of space possible. This rule hides that
  read-only input.
*/
.pickup-time-select
  .v-select__selections
  input[type="text"][readonly="readonly"] {
  display: none;
}
</style>
